import {
  init,
  browserTracingIntegration,
  replayIntegration,
  reactRouterV6BrowserTracingIntegration,
} from '@sentry/react';
import { useEffect } from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

export const initSentry = () => {
  if (import.meta.env.DEV) return;

  const dsn = import.meta.env.VITE_SENTRY_DSN;

  if (dsn) {
    init({
      dsn,
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        browserTracingIntegration(),
        replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost'],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      initialScope: {
        tags: {
          environment: 'production',
        },
      },
      autoSessionTracking: false,
    });
  }
};
