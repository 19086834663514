import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
// import { getEthereumAddress, spotQuantityFromChainQuantity } from '@injectivelabs/sdk-ts';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const validateInjectiveAddress = (address: string) => {
  try {
    // getEthereumAddress(address);
    if (address) return true;
  } catch {
    return false;
  }
};

export const formatBalance = (balance: number) => {
  if (balance < 1 && balance.toString().length > 6) return balance.toFixed(6);
  if (balance < 1) return balance.toFixed(balance.toString().length - 1);
  return balance.toFixed(2);
};

export function formatNumberWithSpaces(num: number | string) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatNumberWithCommas(num: number | string): string {
  const parts = num.toString().split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1];

  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (decimalPart) {
    return `${formattedIntegerPart}.${decimalPart}`;
  }

  return formattedIntegerPart;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const formatMsFromCurrentDate = (nanos: string | null) => {
  if (!nanos) return 'Never';

  const ms = nanos.slice(0, -6);

  const currentDate = new Date();
  const targetDate = new Date(currentDate.getTime() + +ms);

  const hours = String(targetDate.getHours()).padStart(2, '0');
  const minutes = String(targetDate.getMinutes()).padStart(2, '0');
  const day = String(targetDate.getDate()).padStart(2, '0');
  const month = String(targetDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = targetDate.getFullYear();

  return `${hours}:${minutes} ${day}:${month}:${year}`;
};

export const formatNanosToDate = (nanos: string | null) => {
  if (!nanos) return 'Never';

  const ms = nanos.slice(0, -6);

  const date = new Date(+ms);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${hours}:${minutes} ${day}:${month}:${year}`;
};

export const checkOrderDateHasPassed = (nanos: string | null) => {
  if (!nanos) return false;

  const ms = nanos.slice(0, -6);
  const currentTimeMs = Date.now();
  return +ms < currentTimeMs;
};

export const shortenAddress = (
  wallet: string,
  startSymbols: number = 6,
  endSymbols: number = 4,
  maxLength: number = 10
) => {
  if (wallet.length <= maxLength) return wallet;

  return wallet.slice(0, startSymbols) + '...' + wallet.slice(-endSymbols);
};
