import styled from 'styled-components';

export const FitLoader = styled.div`
  width: fit-content;
  height: fit-content;
  display: grid;
  place-content: center;
  padding: 8px;

  --border-angle: 1turn; // For animation.
  --main-bg: conic-gradient(
    from var(--border-angle),
    var(--swap-bg),
    var(--swap-bg) 5%,
    var(--swap-bg) 60%,
    var(--swap-bg) 95%
  );

  border: solid 3px transparent;
  border-radius: 15px;
  --gradient-border: conic-gradient(
    from var(--border-angle),
    #0abdbb 0%,
    var(--load-tail2) 35%,
    var(--load-tail) 70%,
    var(--swap-bg) 99%
  );

  background:
      // padding-box clip this background in to the overall element except the border.
    var(--main-bg) padding-box,
    // border-box extends this background to the border space
    var(--gradient-border) border-box,
    // Duplicate main background to fill in behind the gradient border. You can remove this if you want the border to extend "outside" the box background.
    var(--main-bg) border-box;

  background-position: center center;

  animation: bg-spin 2.5s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 0turn;
    }
  }
  @property --border-angle {
    syntax: '<angle>';
    inherits: true;
    initial-value: 1turn;
  }
`;
