import { FitLoader } from '@/components/fit-loader';
import { FC } from 'react';
import { cn } from '@/lib/utils.ts';

interface IPageLoader {
  screen?: boolean;
}

export const PageLoader: FC<IPageLoader> = ({ screen }) => {
  return (
    <div className={cn('flex-1 w-full flex items-center justify-center bg-swap-bg', screen ? 'h-screen' : 'h-full')}>
      <FitLoader>
        <div className="w-20 h-20" />
      </FitLoader>
    </div>
  );
};
