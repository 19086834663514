import { HelmetProvider } from 'react-helmet-async';
import { LazyRouterProvider } from '@/providers/lazy-router-provider';

export const App = () => {
  return (
    <HelmetProvider>
      <LazyRouterProvider />
    </HelmetProvider>
  );
};
