import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from '@/App.tsx';
import '@/index.css';
import { Buffer } from 'buffer';
import { initSentry } from '@/lib/sentry.ts';

window.Buffer = Buffer;

initSentry();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
